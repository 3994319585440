function searchHeader() {
    $(".banner_nav .header_section").on("click tap touch", "#search_trigger", function () {
        $("#top_search").addClass("actif");
        $('#shade').addClass('visible');
        $(".banner_nav").addClass("scrolled");
        // create a focus in search bar
        $("#search_home").focus();
        $('body').addClass('noscroll');
        $('#shade').addClass('search_shade');
    });
}

function closeSearchHeader() {
    $("#form_recherche").on("click tap touch", ".close_btn.search", function () {
        $("#top_search").removeClass("actif");
        $('#shade').removeClass('visible');
        $(".banner_nav").removeClass("scrolled");
        $('body').removeClass('noscroll');
        $('#shade').removeClass('search_shade');
    });
}
function openSizeAchatExpressV2() {

}
var rayonCategSlider;

$(window).on('scroll', function () {
    // pageYOffset or scrollY
    if (window.pageYOffset > 20) {
        $('.banner_nav').addClass('scrolled');
    } else {
        $('.banner_nav').removeClass('scrolled');
    }
});

/* HEADER SEARCH */
$( "#form_recherche" ).append( '<div class="close_btn search" onclick="closeSearchHeader();"></div>' );

$("#appContainer").ready(function() {
    var mobileMenu = $('.home-mobile-menu');
    mobileMenu.appendTo('#appContainer');
});

// mobile document ready
$(function () {

    $('.under').on('click',function() {
        $('.under').removeClass('active');
        $(this).addClass('active');
    });
    /**
     * Add body class if active bandeau header
     */
    if ($('.bandeauMenuAffix').length) {

        document.body.classList.add('hasBando');
    }

    /**
     * Home mobile menu
     */
    setTimeout(function () {
        if ($('.home-mobile-menu').length) {

            var homeMobileMenu = new Swiper('.home-mobile-menu', {
                slidesPerView: "auto",
                spaceBetween: 24,
            });

            // Add body class on homepage to see copyright when scrolling down
            if (document.body.classList.contains('homepage')) {
                document.body.classList.add('fixedMenuHome');
            }
        }
    }, 500);

    /**
     * Home sliders
     */
    if ($('#coverSwiper').length) {

        // If more than 1 slide
        if ($('#coverSwiper .swiper-slide').length > 1) {

            var coverSwiper = new Swiper('#coverSwiper', {

                slidesPerView: 1,
                loop: true,
                speed: 1500,
                autoplay: 3000,
                navigation: {
                    prevEl: '.coverSwiperPrev',
                    nextEl: '.coverSwiperNext',
                },
            });
        } else {

            var coverSwiper = new Swiper('#coverSwiper', {

                slidesPerView: 1,
                preloadImages: false,
                lazy: true,
            });
        }
    }

    if ($(".bloc_sub_categ").length) {
        const bloc_sub_categ = new Swiper($(".bloc_sub_categ"), {
            roundLengths: true,
            setWrapperSize: true,
            slidesPerView: "auto",
            centeredSlides: false,
            spaceBetween: 30,
        });
    }

    if ($('.pushProductHome').length) {

        var pushProductHome = new Swiper('.pushProductHome', {

            slidesPerView: 2.3,
            allowTouchMove: false,
            spaceBetween: 4,
        });
    }

    if ($('#sliderHomeInsta').length) {

        var sliderHomeInsta = new Swiper('#sliderHomeInsta', {

            slidesPerView: 1.5,
            spaceBetween: 8,
            navigation: {
                prevEl: '.instaPrev',
                nextEl: '.instaNext',
            },
        });
    }

    // page satellite swiper
    if ($('#satelliteSoinSwiper').length) {
        var satelliteSoinSwiper = new Swiper('#satelliteSoinSwiper', {
            slidesPerView: 2.03,
            spaceBetween: 5,
            centeredSlides: false,
        });
    }

    if ($("#swiperRecycle").length > 0) {
        var swiperRecycle = document.getElementById("swiperRecycle");

        if (swiperRecycle.getElementsByClassName("swiper-slide").length) {

            swiperRecycle = new Swiper("#swiperRecycle", {
                loop: false,
                slidesPerView: 1.6,
                spaceBetween: 10,
                pagination: "#swiperRecycle .swiper-pagination",
                paginationType: "progress"
            });
        }
    }

    if ($("#swiperTonne").length > 0) {
        var swiperTonne = document.getElementById("swiperTonne");

        if (swiperTonne.getElementsByClassName("swiper-slide").length) {

            swiperTonne = new Swiper("#swiperTonne", {
                width: 400,
                hashnav: true,
                hashnavWatchState: true,
                nextButton: '.swiper-button-next',
                prevButton: '.swiper-button-prev'
            });
        }
    }

    if ($("#swiperMenu").length > 0) {
        var swiperMenu = document.getElementById("swiperMenu");

        if (swiperMenu.getElementsByClassName("swiper-slide").length) {

            swiperMenu = new Swiper("#swiperMenu", {
                slidesPerView: 2,
                spaceBetween: 30,
            });
        }
    }

    // Swiper nos-boutiques/magasin - #nearby_lists
    if ($("#nearby_lists").length) {
        const nearby_lists = new Swiper($("#nearby_lists"), {
            slidesPerView: 1.025,
        });
    }

    // Swiper nos-boutiques/magasin - #wrapper_visus_store
    if ($('#wrapper_visus_store').length) {
        var wrapper_visus_store = new Swiper('#wrapper_visus_store', {
            slidesPerView: 'auto',
            navigation: {
                prevEl: '.prevRange',
                nextEl: '.nextRange',
            },
        });
    }

    if ($('#descrip_store').length && $('#descrip_store').text().length > 500) {
        var description = $('#descrip_store');
        var trigger = $('#descrip_store .trigger');
        var originH = description.height();

        description.css('height', '220px');
        trigger.show();

        trigger.on('click touch', function () {
            if ($(this).hasClass('open')) {
                description.css('height', '220px');
                trigger.removeClass('open');
                trigger.text(Translator.translate('read_more'));
            } else {
                description.css('height', (originH + 30) + 'px');
                trigger.addClass('open');
                trigger.text(Translator.translate('read_less'));
            }
        });
    }

    $('#container_guide .wrap_table').each(function () {
        $(this).overlayScrollbars({});
    });

    /**
     * Category top slider
     */
    if ($('.rayonCategSlider .swiper-slide').length > 1) {
        rayonCategSlider = new Swiper('.rayonCategSlider', {
            slidesPerView: 'auto',
            spaceBetween: 20,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
        });
    }

    if(!$('.rayonCategSlider .swiper-slide').length) {
        $('.rayonCategSlider .swiper-button-next, .rayonCategSlider .swiper-button-prev').addClass('inactif');
    }

    /**
     * Category SEO txt read more
     */
    if ($('.txt.trimed').length) {

        var trimedTxt = $('.txt.trimed');
        var fullTxt = $('.txt.full');
        var read_more = $('.read_more');
        var read_less = $('.read_less');

        read_more.on('click', function () {

            $(this).hide();
            read_less.show();
            trimedTxt.removeClass('displayed');
            fullTxt.addClass('displayed');
        });

        read_less.on('click', function () {

            $(this).hide();
            read_more.show();
            trimedTxt.addClass('displayed');
            fullTxt.removeClass('displayed');
        });
    }

    // Page recycle
    $("section.collective .read-more.savoir, section.collective .read-less,section.slide .read-more.savoir, section.slide .read-less").on("click tap touch", function () {
        $(this).toggleClass("show");
        $(this).next().toggleClass("show");
        $(this).prev().toggleClass("show");
        $("section.collective .para_txt_hidden, section.slide .para_txt_hidden").slideToggle(500);
    });

    // Page entretien
    if ($('body').hasClass('entretien')) {
        var headerHeight = $('.bandeauMenuAffix').outerHeight() + $('.banner_nav').outerHeight();
        $('.accordion_title').on('click touch tap', function () {
            var element = $(this);
            setTimeout(function () {
                $('html, body.entretien').animate({
                    scrollTop: element.parent().find('.accordion_title').offset().top - headerHeight
                }, 1000);
            }, 500);
        });
    }

    if ($('body.cart').length) {
        // Remove border-bottom on ".wrapperFormPanier" and border-top on ".upselling_panier" if existed
        if ($(".wrapperFormPanier").length && $(".upselling_panier").length) {
            $(".wrapperFormPanier").css("border-bottom", "none");
            $(".upselling_panier").css("border-top", "none");
        }
    }

    // INIT SWIPER TUNNEL
    initSwiperTunnel();

    if ($('.credit_card.actif').length) {
        $('.credit_card.actif').removeClass('actif');
    }

    // Trace for good
    $('#item_trace_box').click(function () {
        if ($(this).hasClass('opened')) {
            $(this).removeClass('opened');
        } else {
            $(this).addClass('opened');
        }
    });

    /**
     * Rappel couleur FP
     */
    if ($('#currentSelectedColor').length) {

        var currentSelectedColor = $('#currentSelectedColor');
        var couleurProd = $('#item_add_form .prodColor:checked + label');

        currentSelectedColor.html(couleurProd.attr('title'));
    }

    // Hide achat express add to card cta
    if ($('body.category').length) {
        $('.item_container .bloc_achat_express .button.achat.valid').css('display','none');
    }

    if ($('body.product_page').length) {
        $('.wrap_product_bottom .wrap_rolloverproduit .form_submit.bloc_achat_express .button.achat.valid').css('display','none');
    }

    // Remove .stop_transform class from swiper TG & resize new swiper-slide when switching colors (On homepage and page ADV only, for module .push-product-module)
    $(window).on("loadProduct_success", function(event) { // Custom event listener triggered when color has been changed succesfully
        if (($('body.homepage').length || $('body.cms_page_new').length) && $('.home-module.push-product-module').length) {
            $('.home-module.push-product-module .pushProductHomeWrapper .pushProductHome .swiper-wrapper').removeClass('stop_transform');

            let viewportWidth = $(window).width();
            let swiperSlideWidth = Math.ceil((viewportWidth - 8) / 2.3); // 8 is the space between slides & 2.3 is the number of slides per view

            $('.home-module.push-product-module .pushProductHomeWrapper .pushProductHome .swiper-wrapper .item_container').css('width', swiperSlideWidth + 'px');
        }
    });

    $('.product_page #carousel_wrapper li.swiper-slide').each(function() {
        var $eclatRect = $(this).find('.eclat_rect');
        if ($eclatRect.length) {
            var $priceContainer = $(this).find('.prix_product_range');
            if ($priceContainer.length) {
                $eclatRect.appendTo($priceContainer);
            }
        }
    });

    /*Set new path for image on checkout page*/
    if ($('body').hasClass('checkout')) {
        var img = $('#bank_div .bank_subtitle span:eq(1) img.bank_locks');

        if (img.length) {
            var src = img.attr('src');
            var newSrc = src.replace(/.*\/img\//, '/img/');
            img.attr('src', newSrc);
        }
    }
});

// moves alma widget to below order recap
$(window).on("changeQte_success wishToBasket_complete basketToWish_complete", function (data) {
    initSwiperTunnel();
    if ($('.order_recap #alma-widget').length) {
        $('#alma-widget').appendTo($('.elem_tunnel.right_side'));
    }
});

/* ADD SCROLL TO TABLE */
if ($('body').hasClass('faq')) {
    $(".faq_reponse").each(function () {
        $(this).find("table").wrap('<div class="table_data"></div>');
    });
}

$(function () {
    /* PAGE BOUTIQUE DETAIL */
    $("<span class='title'>" + Translator.translate('nos_boutiques') + "</span>").prependTo(".satellite_store_details #appContainer");
    $("#go_to_store_container").insertBefore("#send_store_information");

    /* FOOTER ACCORDION */
    setTimeout(function () {
        $('.ctn .title').each(function () {
            $(this).click(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $(this).parent().find('.footer_navs').removeClass('active').slideUp(300);
                }
                else {
                    $('.ctn .title').removeClass('active');
                    $('.ctn .footer_navs').removeClass('active').slideUp(300);
                    $(this).addClass('active');
                    $(this).parent().find('.footer_navs').addClass('active').slideDown(300);
                }
            });
        });
    }, 1000);
});

// Init swiper mon compte
if ($('#menu_account_slider').length && ($('#menu_account_slider.swiper-slide').length > 1)) {
    var menuInitialSlide = $('#menu_account_slider .swiper-slide.activeMenu').index();

    setTimeout(function () {
        new Swiper('#menu_account_slider', {
            slidesPerView: 'auto',
            initialSlide: menuInitialSlide
        });
    }, 300);
}

// Move selected article to the top
if ($('body').hasClass('wishlist')) {
    $('#nrArticlesSelected').insertBefore('.wrapper_btn_top');
}

// Accordion Menu
$(function () {
    
    // Wait for load_partials.js to be executed so that #site_head does not have its content refreshed after this code is executed
    $(window).on("accountLoad_complete", function() {
        $('#main_menu_btn').addClass('enabled');

        function menuTopAcc(firstLevelTitle, submenuWrapper) {
            $(submenuWrapper).hide();

            $(firstLevelTitle).each(function () {
                $(this).on('click tap touch', function (event) {
                    if (!$(this).hasClass('active')) {
                        $(firstLevelTitle + ', ' + submenuWrapper).removeClass('active');
                        $(submenuWrapper).slideUp(300);
                        $(this).addClass('active');
                        $(this).next().addClass('active').slideDown(300);
                    }
                    else {
                        $(this).removeClass('active');
                        $(this).next().removeClass('active').slideUp(300);
                    }
                });
            });
        }

        var leftMenu = "#left_menu .menu_part.bottom";
        var firstLevelTitle = leftMenu + " .first_level_item.has_submenu";
        var submenuWrapper = leftMenu + " .submenu_wrapper";
        menuTopAcc(firstLevelTitle, submenuWrapper);

        var subSubTitle = leftMenu + " .first_level_item.has_submenu + .submenu_wrapper .ss_cat li";
        var subSubWrapper = subSubTitle + " + .only_categ_name";
        menuTopAcc(subSubTitle, subSubWrapper);

        /* menu compte when login */
        var accountTitle = ".general_menu .menu_item .connected.dropdown span";
        var accountSubmenu = ".general_menu .menu_item .connected.dropdown .dropdown.mon-compte";
        menuTopAcc(accountTitle, accountSubmenu);

        $("#left_menu .general_menu .menu_item a.bloc_lnk.user span").text(Translator.translate('mon_compte_titre'));

        /* SWITCH LANGUES MENU */
        if ($('#left_menu .switchLangWrapper').length) {
            $('#left_menu .switchLangWrapper .current').on('click touch', function () {
                $(this).toggleClass('open');
                $('.switchLangWrapper .langues').slideToggle();
            });
        }
    });

    // Ajout de CTA sur la page connexion.php
    if ($('body').hasClass('body_login')) {
        const emailInput = $('input[name="userbdd"]');
        const translatedText = Translator.translate('login_en_savoir_plus');

        emailInput.on('input paste change', () => {
            setTimeout(() => {
                if ($('.content_form.sponsor').length && !$('.en_savoir_plus_cta').length) {
                    $('.wrapper_input_sponsor').prepend(`<a href="${path_relative_root + create_link('faq')}?theme=5#&source=cta" class="en_savoir_plus_cta" target="_blank">${translatedText}</a>`);
                }
            }, 250);
        });
    }

    if ($('body').hasClass('faq')) {
        $(document).ready(function () {
            const targetUrlSubstring = "faq.php?theme=5#";
            const ctaSource = "&source=cta";
            const currentUrl = window.location.href;

            if (currentUrl.includes(targetUrlSubstring) && currentUrl.includes(ctaSource)) {
                const parrainageQuestion = $(".faq_question_75");
                const parrainageReponse = $(".faq_reponse_75");

                if (parrainageQuestion.length && parrainageReponse.length) {
                    parrainageQuestion.addClass("active");
                    parrainageReponse.show();

                    setTimeout(() => {
                        $('html, body').animate({
                            scrollTop: parrainageQuestion.offset().top - 105
                        }, 2000);
                    }, 1000);
                }
            }
        });
    }
});

function loadProduct(type_filter, id, type_page, itm_rr_id) {
    var tmp_qte = $('#qteProd_' + id).val();
    var is_wishlist = false;
    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);
    var isProdKit = (typeof(produit_kit_id) !== 'undefined'); // permet de reload le bloc d'image pour afficher les différentes images de chacun des produits du kit

    if (isProdKit) {
        id_prod = $(this).attr('data-idprod-img');
    } else if (typeof (id) == 'undefined') {
        id_prod = "";
    } else {
        id_prod = "_" + id;
    }

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    var $form = '';
    var data = '';

    if (!is_achat_express_v2) {
        $form = $('#item_add_form');
        var $input = $('#prod_' + type_filter + id_prod, $form);
        var type = type_filter;
        var $hidden = $('#prod_' + type_filter + id_prod, $form);

        if (typeof (id) == 'undefined') {
            data += $form.serialize();
        }

        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            $produit_id_new = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $("#prod_couleur").val();
            $produit_id_new = $('#prod_' + type_filter + id_prod).find(':selected').attr('data-produitid');
        }

        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=' + $("#prod_taille").val();
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        if (document.getElementById('wishlist')) {
            data += '&type_page=' + type_page;
        }

        if (typeof (id) != 'undefined') {
            data += '&couleurProd=' + $('#couleurProd' + id_prod).val();
            data += '&tailleProd=' + $('#tailleProd' + id_prod).val();
            data += '&produit_principal=' + $('#produit_principal' + id_prod).val();
            var produit_principal = $('#produit_principal' + id_prod).val();
        }

        data += '&produit_id_new=' + $produit_id_new;
        var elemClass = $(".reloadProduct") ? ".reloadProduct" : ".swiper-slide"; //Sélection de la class  sur le vertical_product pour le reload du produits (Master = 'reloadProduct' , Override = 'swiper-slide') ex: Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass);

    } else {
        var elemClass = $(".reloadProduct").length ? ".reloadProduct" : ".swiper-slide";  //Sélection de la class  sur le vertical_product pour le reload du produits (Master = 'reloadProduct' , Override = 'swiper-slide') ex: Manoush
        var div_produit_achat_express_v2 = $(this).parents(elemClass);
        var swiper_achat_express_v2 = $(this).parents('.swiper-container');

        $form = $(this.form);
        var id_prod = "";
        $form = this.form;

        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = this.form.elements['isCategSuper'].value;
        var new_id = id;
        itm_rr_id = itm_rr_id !== "" ? "_" + itm_rr_id.split("_")[1] : "";
        var cpt_prod = itm_rr_id !== "" ? itm_rr_id.replace("_", "") : "";

        // to avoid calling the view vertical_product.php in category_super/layouts when product's color is changed in rayon
        if ($('body.category .page_rayon').length) {
            var isCategSuper = 0;
        }

        var couleur_id = 0;
        if (isProdKit) {
            couleur_id = $(this).attr('value');
            new_id = $(this).attr('data-idprod-img');
        } else {
            couleur_id = $(this).val();
        }

        data += 'produit_id=' + old_id;
        data += '&produit_principal=' + old_id;
        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=';
        data += '&qteProd=1';
        data += '&hdn_product_id=' + old_id;
        data += '&produit_id_new=' + new_id;
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        data += '&is_achat_express=0';
        data += '&is_achat_express_v2=1';
        data += '&cpt_prod=' + (cpt_prod - 1);
        data += '&isCategSuper=' + isCategSuper;
    }

    data += '&warehouse_only=' + (isGlobalEActive ? 1 : 0);

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_product_regroup_ref'),
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function (response) {

            try {
                response = $.parseJSON(response);
            }
            catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'view_item',
                    });
                }

                var datas = response.datas;

                if (type_page == 'product') {

                    var $html_main = $(datas.html_main);

                    if (isProdKit) {
                        //set slider product
                        $("#zoom_container").replaceWith($("#zoom_container", datas.html_main));

                        if ($("#zoom_container .swiper-slide").length > 1) {
                            var slidesPerView = $('#zoom_container').data('slidesperview');
                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }
                            var spaceBetween = $('#zoom_container').data('spacebetween');
                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            }
                            var prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
                                slidesPerView: slidesPerView,
                                mode: 'horizontal',
                                centeredSlides: true,
                                pagination: '.swiper-pagination',
                                paginationClickable: true,
                                preloadImages: true,
                                lazyLoading: true,
                                spaceBetween: spaceBetween,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }
                    } else if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if ($('#oneProduct') != undefined && $('#oneProduct').hasClass('actif')) {
                            $('.itm_achat_express_' + new_id).addClass('full');
                        }
                        if ($('.container_products_lookbook').length > 0) {
                            //Evite de casser le style pour kujten
                            if ($('#is_from_kujten') == undefined) {
                                $html_main.removeClass('item_container');
                                $html_main.addClass('item');
                            }
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof (four_images) != 'undefined') {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr('id');
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }

                        // Remove shad
                        $("#shade").removeClass("isExpress").removeClass("visible");

                        // page magasin - remove .stop_transform_gondola
                        $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper').removeClass('stop_transform_gondola');
                        $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper .swiper-slide').removeClass('stop_transform_gondola');

                        $('#entretien_gondole .tete_de_gondole.swiper-wrapper').removeClass('stopTransform');
                        $('#entretien_gondole .tete_de_gondole .swiper-slide').removeClass('stopTransform');
                        setTimeout(function () {

                            initItemSlider(false);

                            if ($('.assoSlider').length) {

                                $('.assoSlider').each(function () {

                                    this.swiper.update();
                                });
                            }
                        }, 200);
                    } else {

                        var $fiche = $('#contents_wrapper');
                        var replace_main = new Array(
                            '#wrapper_product_name',
                            '#zoom_container',
                            '.prod_page_bot',
                            '.compo',
                            '.associations_section',
                            '.accordion_container',
                            '.product_infos .price_container',
                            '.cms-page-module',
                            'w-netreviews-stars',
                            'w-netreviews-comments',
                            '.description_courte',
                            '.caracNav',
                            '.resume',
                            '.netreviews_reviews'
                        );

                        var product_refext_prod = $('input[name=itm_refext_prod]', $html_main).val();

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id == '.accordion_container') {

                                // Recover each accordion_item id so we can replace each one individually
                                $('.accordion_item', $fiche).each(function () {
                                    var acc_item_id = $(this).attr('id');

                                    if ($("#" + acc_item_id, $html_main).length) {
                                        // Is item was previously hidden, remove cache class
                                        if ($(this).hasClass('cache')) {
                                            $(this).removeClass('cache')
                                        }

                                        // Update item content in fiche with ajax response content
                                        $(this).html($("#" + acc_item_id, $html_main).html());
                                    } else {
                                        // If fiche accordion item isn't present in ajax response, hide it by adding cache class
                                        $(this).addClass('cache');
                                    }
                                });

                            } else if (id == '.description_courte') {
                                // // Bloc pas accessible directement sur html_main (méthodo juste au-dessus), obligé de contourner avec cette méthode - EX : Messegue
                                $html_main.each(function () {
                                    if ($(this).hasClass('description_courte')) {
                                        $('.description_courte', $fiche).html($(this).html());
                                    }
                                });
                            } else if (id == '.caracNav') {
                                // Example : Sabon FP Description --> Item description isn't build as an accordion like most wshops
                                // Description is working as tabs

                                // Titles
                                $('.caracLabel', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                                // Contents matching titles
                                $('.caracWrapper', $html_main).each(function () {
                                    var item_id = $(this).attr('id');
                                    $("#" + item_id, $fiche).html($(this).html());
                                });

                            } else if (id == 'w-netreviews-stars') { // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html($('w-netreviews-stars', $html_main).html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsStars) {
                                    // Avis verifies stars
                                    if (wShop.$refs.wNetreviewsStars != 'undefined') {
                                        wShop.$refs.wNetreviewsStars.reload(product_refext_prod);
                                    }
                                }
                            } else if (id == 'w-netreviews-comments') { // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith($('w-netreviews-comments', $html_main).wrap('<p/>').parent().html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsComments) {
                                    // Avis verifies reviews
                                    if (wShop.$refs.wNetreviewsComments != 'undefined') {
                                        wShop.$refs.wNetreviewsComments.reload(product_refext_prod);
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html($(id, $html_main).html());
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith('');
                                    }
                                }

                                /******* OLD AVIS VERIFIES ************/
                                if (id == '.netreviews_reviews') {
                                    if ($('#avis_verifie_produit').length || $('.netreviews_reviews').length || $('.netreviews_stars').length) {
                                        if (typeof netreviews_product_url != 'undefined' && netreviews_product_url !== null) {
                                            // netreviews_product_url defined in views_mobile_new/main.php
                                            (function () {
                                                var script = document.createElement('script');
                                                script.setAttribute('id', 'ajax_verified');
                                                script.setAttribute("type", "text/javascript");
                                                script.setAttribute("src", netreviews_product_url);

                                                // If ajax already being done, verify if script exists before adding it to HEAD
                                                if (document.getElementById('ajax_verified') !== null) {
                                                    document.querySelector('#ajax_verified').remove();
                                                }

                                                document.getElementsByTagName("head")[0].appendChild(script);
                                            })();
                                        }
                                    }

                                    if (typeof nrSearchForStars !== "undefined" && $('.NETREVIEWS_PRODUCT_STARS').length) {
                                        nrSearchForStars();
                                    }

                                    // Display avis verifie comments
                                    // Call nrSearchForReviews from tag.min.js avis verifie NETREVIEWS_PRODUCT_URL
                                    if (typeof nrSearchForReviews !== "undefined" && $('.NETREVIEWS_PRODUCT_REVIEWS').length) {
                                        nrSearchForReviews();
                                        setTimeout(function() {
                                            if ($('#netreviews_reviews_tab').height() === undefined) {
                                                $("#avis_verifies").hide();
                                            } else {
                                                $("#avis_verifies").show();
                                            }
                                        }, 1500);
                                    }
                                }
                            }
                        }

                        /******* ASSOCIATED PRODUCTS ************************************/
                        /***************************************************************/

                        var slides_per_view = typeof associatedProduct_spv != "undefined" ? associatedProduct_spv : 3;
                        var asso_btn_prev = typeof assoBtnPrev != "undefined" ? assoBtnPrev : ".swiper-button-prev";
                        var asso_btn_next = typeof assoBtnNext != "undefined" ? assoBtnNext : ".swiper-button-next";
                        var asso_space_between = typeof assoSpaceBetween != "undefined" ? assoSpaceBetween : 20;
                        var asso_swiper_params = typeof assoSwiperParams != "undefined" ? assoSwiperParams : {
                            slidesPerView: slides_per_view,
                            spaceBetween: asso_space_between,
                            nextButton: asso_btn_prev,
                            prevButton: asso_btn_next,
                        };

                        // Associations_section = tabs (=> onglets)
                        if ($('.associations_section', $html_main).length > 0) {

                            // Check if associated products are displayed as tabs
                            if ($('.product_associations_wrapper', $html_main).attr('data-tab') !== undefined) {
                                // First check if there's an active class on each titles and wrappers
                                if (!$('.association_title.active').length) {
                                    $('.association_title').first().addClass('active');
                                }

                                if (!$('.product_associations_wrapper.active').length) {
                                    $('.product_associations_wrapper').first().addClass('active');

                                    // Init also swiper on page load
                                    if ($('.product_associations_wrapper.active .swiper-container .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper('.product_associations_wrapper.active .swiper-container', asso_swiper_params)
                                    }
                                }

                                // Once first title and first swiper is active, handle click on each tabs dynamically
                                $('.association_title').on('click', function () {

                                    var title_tab = $(this).attr('data-tab');
                                    var active_tab = $('.product_associations_wrapper.active');

                                    // If the clicked title is not active yet, set it to active and remove active class from the last title
                                    if (!$(this).hasClass('active')) {
                                        $('.association_title.active').removeClass('active');
                                        $(this).addClass('active');
                                    }

                                    // If new active title, display matching associations tab
                                    if (title_tab != active_tab.attr('data-tab')) {
                                        active_tab.removeClass('active');
                                        $('.product_associations_wrapper[data-tab="' + title_tab + '"]').addClass('active');

                                        // Swiper activation
                                        if ($(".swiper-container", '.product_associations_wrapper[data-tab="' + title_tab + '"]')) {
                                            // If more than slides_per_view, we activate the swiper
                                            if ($('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-slide').length > Math.floor(asso_swiper_params.slidesPerView)) {
                                                setTimeout(new Swiper('.product_associations_wrapper[data-tab="' + title_tab + '"] .swiper-container', asso_swiper_params),500);
                                            }
                                        }
                                    }
                                });

                            }
                        } else {

                            // Init product_associations_wrapper swiper(s)
                            if ($('.product_associations_wrapper').length > 1) {

                                $('.product_associations_wrapper').each(function() {
                                    if ($(this).find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                        new Swiper(($(this).find('.swiper-container')), asso_swiper_params)
                                    }
                                });
                            } else if ($('.product_associations_wrapper').length > 0) {
                                if ($('.product_associations_wrapper').find('.swiper-slide').length >= Math.floor(asso_swiper_params.slidesPerView)) {
                                    new Swiper('.product_associations_wrapper .swiper-container', asso_swiper_params);
                                }
                            }
                        }

                        // If there's not an associations_section working with a tabulation mechanism -> check carousel_wrapper
                        if (!$('.associations_section', $html_main).length) {
                            if ($('#carousel_wrapper', $html_main).length > 0) {
                                // For sites using the _app view, some sites don't want one particular swipers on reload so remove it from dom
                                if (typeof carouselException != 'undefined') {
                                    var new_carousel = $('#carousel_wrapper', $html_main);

                                    // Removing carousel exception from html_main response
                                    if ($('.ranges_carousel_wrapper.' + carouselException, new_carousel).length) {
                                        $('.ranges_carousel_wrapper.' + carouselException, new_carousel).remove();
                                    }

                                    $('#carousel_wrapper').html(new_carousel.html());
                                } else {
                                    $('#carousel_wrapper').html($('#carousel_wrapper', $html_main).html());
                                }

                                // Check if site has declared several associated swipers for its FP
                                if (typeof oAssociatedSwipers != 'undefined' && typeof oAssociatedSwipers == 'object') {

                                    // Loop and init swipers
                                    $.each(oAssociatedSwipers, function (container, params) {
                                        // Check if there are enough slides to init swiper
                                        if ($(container).length > 0) {
                                            if ($(container).find('.swiper-slide').length >= params.slidesPerView) {
                                                // Check if swiper-container is a sibling or a child
                                                setTimeout(
                                                    new Swiper($(container), params)
                                                , 500);
                                            }
                                        }
                                    })
                                } else {
                                    // If swipers are identical, init them the classic way
                                    if ($('#carousel_wrapper .swiper-container').length > 1) {
                                        $('#carousel_wrapper .swiper-container').each(function () {
                                            if ($(this).find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                                setTimeout(
                                                    new Swiper($(this), asso_swiper_params)
                                                , 500);
                                            }
                                        });
                                    } else if ($('#carousel_wrapper .swiper-container').length > 0) {
                                        if ($('#carousel_wrapper .swiper-container').find('.swiper-slide').length >= asso_swiper_params.slidesPerView) {
                                            setTimeout(
                                                new Swiper('#carousel_wrapper .swiper-container', asso_swiper_params)
                                            , 500);
                                        }
                                    }

                                }
                            } else {
                                $('#carousel_wrapper').html('');
                            }
                        }
                        if ($('#publication_page', $html_main).length > 0) {
                            $('#publication_page').html($('#publication_page', $html_main).html());
                        } else {
                            $('#publication_page').html('');
                        }

                        window.prod_slider = document.getElementById("product_pictures_slider");
                        window.asso_slider = document.getElementById('asso_product_carousel');
                        window.gamm_slider = document.getElementById('gamme_product_carousel');

                        /** Swiper function for gamme && assoc products
                         * TODO => Call it for assoc also
                         */
                        var swiperGamme = function (element, isCenteredSlides, isLoop) {
                            if ($("body.product_page ").length) {
                                // Destroy gamme_product_carousel swiper
                                document.querySelectorAll('.swiper-container').forEach(function(container) {
                                    if ($(container).hasClass("gamme_product_carousel") && container.swiper) {
                                        container.swiper.destroy(true, true);
                                    }
                                });
                            } else {
                                new Swiper(element, {
                                    slidesPerView: 3,
                                    centeredSlides: isCenteredSlides,
                                    paginationClickable: true,
                                    spaceBetween: 20,
                                    pagination: '.swiper-pagination',
                                    preloadImages: true,
                                    lazyLoading: true,
                                    loop: isLoop,
                                    nextButton: '.swiper-button-next',
                                    prevButton: '.swiper-button-prev'
                                });
                            }
                        }

                        if (asso_slider && $('.swiper-slide', asso_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {
                            if ($("body.product_page ").length) {
                                // Destroy asso_product_carousel swiper
                                document.querySelectorAll('.swiper-container').forEach(function(container) {
                                    if ($(container).hasClass("asso_product_carousel") && container.swiper) {
                                        container.swiper.destroy(true, true);
                                    }
                                });
                            } else {
                                var assoSwiperSlider = new Swiper('.swiper-container.asso_product_carousel', {
                                    slidesPerView: 3,
                                    centeredSlides: true,
                                    paginationClickable: true,
                                    spaceBetween: 20,
                                    pagination: '.swiper-pagination',
                                    preloadImages: true,
                                    lazyLoading: true,
                                    loop: true,
                                    nextButton: '.swiper-button-next',
                                    prevButton: '.swiper-button-prev'
                                });
                            }
                        }

                        if (gamm_slider && $('.swiper-slide', gamm_slider).length > 0 && (typeof assoSwiperParams == "undefined")) {

                            if ($(window.gamm_slider).hasClass('noLoop')) {

                                /** Gamme swiper without loop => prevent products repetition bug */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', false, false);
                            } else {

                                /** Default gamme swiper */
                                gammSwiperSlider = swiperGamme('.swiper-container.gamme_product_carousel', true, true);
                            }

                        }

                        /* Special case for a single view slider */
                        if ($('.assocSlider').length > 0 && $('.assocSlider .swiper-slide').length >= 1  && (typeof assoSwiperParams == 'undefined')) {
                            var assoSlider = new Swiper('.assoSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        if ($('.gammeSlider').length > 0 && $('.gammeSlider .swiper-slide').length >= 1 && (typeof assoSwiperParams == "undefined")) {
                            var gammSlider = new Swiper('.gammeSlider', {
                                slidesPerView: 1,
                                spaceBetween: 0,
                                preloadImages: true,
                                lazyLoading: true,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        //set slider product
                        if (prod_slider && $(".swiper-slide", prod_slider).length > 1) {
                            var slidesPerView = $('#zoom_container').data('slidesperview');
                            if (slidesPerView == undefined) {
                                slidesPerView = 1;
                            }
                            var spaceBetween = $('#zoom_container').data('spacebetween');
                            if (spaceBetween == undefined) {
                                spaceBetween = 0;
                            };
                            var prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
                                slidesPerView: slidesPerView,
                                mode: 'horizontal',
                                centeredSlides: true,
                                pagination: '.swiper-pagination',
                                paginationClickable: true,
                                preloadImages: true,
                                lazyLoading: true,
                                spaceBetween: spaceBetween,
                                loop: true,
                                nextButton: '.swiper-button-next',
                                prevButton: '.swiper-button-prev'
                            });
                        }

                        var string_params = '';
                        if ($("#produit_lot_nb").val() != undefined) {
                            string_params += '&produit_lot_nb=' + $("#produit_lot_nb").val();
                        }

                        if ($('#tailleProd').val() > 0) {
                            // RECHARGEMENT QTE
                            var qte = ajax_file(path_relative_root + 'ajax_liste_qte.php?idprod=' + datas.product.id + '&idcouleur=' + datas.product.color.id + '&idtaille=' + $('#tailleProd').val() + string_params);
                            $('#liste_qte').html(qte);
                        }

                        // If we have one size we directly check the stock of the product (cf VB)
                        if ($('input[name="itm_one_size"]').length && $('input[name="itm_one_size"]').val() === '1') {
                            onAttrSelect(parseFloat(datas.product.id));
                        }

                        var contentMovable = $('#content_movable');

                        $('.jqzoom').on('doubletap', function () {
                            zoomImg.call(this);
                            $('#zoomBox').addClass('active');

                            contentMovable.addClass('visible');
                            var zoomboxWidth = $('body').width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $('#zoomBox').scrollLeft(scrollTo);
                            }, 'slow');
                        });

                        contentMovable.on('doubletap', function () {
                            $(this).removeClass('visible');
                            $('#zoomBox .btn_close').trigger('click');
                        });

                        //check stock product
                        var hasStock = false;
                        var stocks = response.stock;
                        $.each(stocks, function(index, stock) {
                            $.each(stock, function (key, val) {
                                if (val === true) {
                                    hasStock = true;
                                }
                            });
                        });

                        //si on a du stock, on affiche les éléments correspondant
                        if (hasStock) {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").show();
                            }

                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").show();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                $("#bloc_add_alert").hide();
                            }
                            //sinon, on les cache
                        } else {
                            if ($("#bloc_add_basket") !== undefined) {
                                $("#bloc_add_basket").hide();
                            }
                            if ($("#add_basket_wrapper") !== undefined) {
                                // Hide btn when product is not available as it's done in product_form.php
                                $("#add_basket_wrapper").hide();
                            }
                            if ($("#link_book_in_store") !== undefined) {
                                $("#link_book_in_store").hide();
                            }
                            if ($("#bloc_add_alert") !== undefined) {
                                if ($('#prod_taille').length) {
                                    var option_selected = false;
                                    $('#prod_taille option').each(function() {
                                        if ($(this).is(':selected')) {
                                            option_selected = true;
                                            return option_selected;
                                        }
                                    });
                                }
                                if (option_selected) {
                                    $("#bloc_add_alert").show();
                                }
                            }
                        }

                        // Cas une seule taille
                        if ($("#prod_taille option").length==2) {
                            getAvailableQuantity(parseFloat(datas.product.id), false, '');
                        }
                    }
                    if ($('#oneProduct').length && $('#oneProduct').hasClass('actif')) {
                        $('div.item_container').addClass('full');
                        $('div.show_push_one_product').show();
                    }
                } else if (type_page == 'look') {

                    var $html_main = $(datas.main_content);

                    $('#ref_look_' + produit_principal).html($('#ref_look_' + produit_principal, $html_main).html());
                    $('#look_add_lightbox_' + produit_principal).html($('#look_add_lightbox_' + produit_principal, $html_main).html());

                    calculTotal('slide_look_detail');

                    var $id_current = id_prod.substring(id_prod.lastIndexOf("_") + 1);
                    var i = array_look.indexOf(parseInt($id_current));

                    if (i != -1) {
                        array_look.splice(i, 1);
                    }

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);

                } else if (type_page == 'assoc') {

                    var $html_main = $(datas.html_main);
                    $('#ref_assoc_' + produit_principal).html($('#ref_assoc_' + produit_principal, $html_main).html());

                    // Une fois les informations chargés on vas mettre à jour le select des quantités avec le nouveau produit choisie
                    showQteListeGamme('liste_qte_' + datas.product.id, datas.product.id, path_relative_root);
                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function () {
                    $(this).selectStyled();
                });

                if ($('#btn_add_alert', response.datas.html_main) && $('#btn_add_alert', response.datas.html_main).attr('onclick') && $('#btn_add_alert', response.datas.html_main).attr('onclick').indexOf('lightboxAlertStock') !== false) {
                    $(".eclat_product_nouveaute_fiche_produit").show();
                }

            } else {
                $('#btn_error').fadeIn();
            }

            if (document.getElementById("alma-widget") !== null) {
                initAlmaWidget();
            }

            // Créate CustomEvent "loadProduct_success" to succes LoadProduct
            var evt = new CustomEvent("loadProduct_success");
            window.dispatchEvent(evt);
        },
        complete: function () {

            $('#bloc_btn_loader').fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            var form = document.getElementById('item_add_form');
            if (!isProdKit) {
                if (is_achat_express_v2) {
                    form = $form;
                } else {
                    colors = form.itm_colour;

                    updateColor.call(colors.options[colors.selectedIndex]);
                }
            }

            setTimeout(function () { $(".zoom_indicator").fadeOut(800) }, 2500);

            // Create CustomEvent "loadProduct_complete" to complete LoadProduct
            var evt = new CustomEvent("loadProduct_complete");
            window.dispatchEvent(evt);

            // WPOS Exclusive - Get if product is available in store use in WPOS
            var idStore = getCookie('wshopStoreId');
            var idProduct = getCookie('p_id');
            if (idStore != undefined && idStore > 0) {
                var color = $("#prod_couleur").val();
                var size = $("#prod_taille").val();
                getProductInfoByStore(idProduct, color, size, idStore)
            }
        }

    });
}

// FAQ
function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        $('#theme_page .theme').each(function () {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length > 3) {
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {
                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }
            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_global').removeClass('actif');
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        $('#theme_global .theme').not($(this)).removeClass('actif');
        $(this).toggleClass('actif');
        retour.addClass('actif');
        theme.addClass('actif');
        theme.show();
        showTabFaq(id, 1);
        $("html, body").animate({ scrollTop: 0 }, "slow");
    });

    $('#reset_faq').on('click', function () {
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').addClass('actif');
        theme.removeClass('actif');
        theme.hide();
        retour.removeClass('actif');
        showTabFaq();
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);

        if (!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function () {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");

        setTimeout(function () {
            var faqQHeight = $(".faq_question").height();
            var headerHeight = $("nav.banner_nav").height() + $(".bandeauMenuAffix").height();
            $("html, body.faq").animate({ scrollTop: $(".faq_question_" + question_id).offset().top - (-faqQHeight + headerHeight + 50) }, 600);
        }, 500);

    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function () {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function (idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function () {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function () {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function () {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });

        $('html, body.faq').animate({
            scrollTop: $("#theme_page").offset().top
        }, 1000);
    }
}

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    var divId = 'popup_numero_retour_' + (multiTransp ? idOrderTransp : idOrder);

    var numDIVObjet = document.getElementById(divId);

    numDIVObjet.style.display = "block";
    numDIVObjet.style.opacity = "1";
    numDIVObjet.style.zIndex = "20";
    document.getElementById("general_shade").style.display = "block";
    document.getElementById("general_shade").style.opacity = ".4";
    document.getElementById("general_shade").style.position = "fixed";
    document.getElementById("general_shade").style.zIndex = "15";
    document.getElementById("general_shade").style.left = "0";
    document.getElementById("general_shade").style.top = "0";
    document.getElementById("general_shade").style.width = "100vw";
    document.getElementById("general_shade").style.height = "100vh";
    document.getElementById("general_shade").style.backgroundColor = "black";

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({ scrollTop: 0 }, (speed * 2), function () {
    });
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_create_etiquette'),
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, isFrom) {

    type = $("#type").val();
    idObj = $("#idObj").val();
    bottom_page = parseInt($('#bottom_page').val()) - 1;

    var nb_product = nb_product || parseFloat($('#nb_prod').val());

    output = triggerFilter(page, type, idObj, nb_product, 'filters_form');

    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    if (page !== undefined && page !== 'all') {
        if (page == 0) {
            output.nb_prod = parseInt($('#nb_prod').val()) + parseInt($('#push_count').val());
        }
        else if (page != 0) {
            output.page = $('#scroll_items .item_container:not(".push")').length;
        }
    }

    if (page !== undefined && page !== 'all') {
        this.page.value = page * nb_product;
        is_from_page = true;
    }

    if ($('#oneProduct').hasClass('actif')) {
        var one_product = true;
    } else {
        var one_product = false;
    }

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }
    // Add misc values to output object
    output.type = type;
    output.parentPath = path_relative_root;
    output.idObj = idObj;
    output.is_page = is_from_page;
    output.nb_tot_prod = parseInt($('#totalElems').attr('data-origin'));
    output.object_id = this.object_id.value;

    if (typeof id_category_super != 'undefined')
        output.id_category_super = id_category_super;

    if ($('input[name="promo"]:checked').length == 0) {
        $('#is_marque').val('');
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    output.handle_push = 'false';

    /** LEGACY **/
    //Recuperation du tri par prix (descendant ou ascendant) qui passe, malheureusement, par l'url
    var url = document.location.href;
    var pattern = new RegExp('\/t([0-9]*)(\/m(0|1))*\.html$');
    var pattern2 = new RegExp('\/e([0-9]*)\.html$');
    var tri = '';
    // Si il y a un ordre dans les prix on charge l'id
    if (pattern.test(url)) {
        var res = url.match(pattern);
        tri = res[1][0];
    }
    /** END LEGACY **/
    if (tri != 0) {
        output.tri = tri;
    }

    if (bloc_prod_xhr) {

        bloc_prod_xhr.abort();
    }

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + create_link('ajax_reload_products'),
        type: 'get',
        data: output,
        success: function (res) {

            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {

                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            // This block of code updates the SEO-related link elements in the HTML document.
            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='x-default']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                $('.wrap_flitre_pager .pagerNav.end_pagi').show();

                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (fromScrollBottom || fromScrollTop) {
                    $(".autoLoad").fadeTo("fast", 0);
                } else {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;

                document.getElementById('totalElems').value = itm_length;
                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                    $('div.show_push_one_product').show();
                }

                /** LEGACY **/

                var js_compteur = $('.vf_countdown').length;
                var js_encart = content.match(/\/\/vf_begin([\s]+[\w$() {}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                        }
                    } catch (e) {
                    }
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                        eval(js_encart);
                    } catch (e) {
                    }
                }

                /** END LEGACY **/

                if (parseFloat($('#filters_menu').css('right')) === 0) {

                    toggleMenu('filters', 'right');
                }

                if (window.lazyload || typeof lazyload != 'undefined') {

                    lazyload.init();
                }

                if (typeof lazyloadImage === 'function') {
                    lazyloadImage();
                }
            } else {
                content = '<div class="no_results_wrapper">' +
                    '<div class="search_details_wrapper">' +
                    '<div class="no_result_block">' +
                    Translator.translate('no_result_txt') +
                    '</div>' +
                    '</div>' +
                    '</div>';
                old_elm.innerHTML = content;
            }

            // Init item slider
            initItemSlider(false);
        },
        complete: function () {
            if ($("body.category").length) {
                addClickTrigger(page+1);
            }

            if ($("#scroll_items #list_item .no_results_wrapper").length) {
                // Hide pagination when no result
                $('.pagerNav.end_pagi').hide();
            } else {
                // Show pagination when result available
                $('.pagerNav.end_pagi').show();
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();
                }
            }

            updateLoadedGauge();

            $('.nbProdDisplayed').removeClass('loading');

            initDailymotionVideos();
        }
    });
}

// Eresa CTA : Checks if size selected then shows pop in eresa
function checkCartEresa() {

    var form = document.getElementById('item_add_form'),
        shad = document.getElementById('general_shade'),
        itm_id = (form.itm_id) ? form.itm_id.value : null,
        value = getInputVal.bind(form),
        head_offset = (window.innerWidth * 0.2921875),
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop,
        data, has_errors;

    if (!itm_id) {
        return false;
    }
    data = {
        mobile: 1,
        product_id: itm_id,
        size_id: value('itm_size'),
        color_id: value('itm_colour'),
        need_color_id: !!form.itm_colour,
        need_size_id: !!form.itm_size,
        is_model: value('itm_is_model')
    };
    form.itm_colour.classList.remove('error');
    form.itm_size.classList.remove('error');
    has_errors = false;

    if ((data.need_color_id && !data.color_id) || (data.need_size_id && !data.size_id)) {

        $('html, body').animate({scrollTop: form_offset - head_offset}, 400);

        if (!data.color_id) {

            form.itm_colour.classList.add('error');
        }

        if (!data.size_id) {

            form.itm_size.classList.add('error');
        }

        return false;
    }
}

var eresa_v2 = true;
function eresaReady() {
    checkCartEresa();

    if (checkCartEresa() !== false) {
        Eresa.show();
    }
}

$(document).ready(function() {
    if ($('body').hasClass('product_page')) {
        $('.first .accordion_title').click();
    }

    initDailymotionVideos();
});

// LOOKBOOK
if ($("body.category .in_lookbook.lookbook").length) {
    $("body.category").addClass("lookbook");
}

// MENU LEFT
function optimiseBandeauMenuAffixHeight() {
    setTimeout(function () {
        var checkHeight = $(".bandeauMenuAffix").height();

        if (checkHeight != 0) {
            $("#left_menu").css("margin-top", checkHeight);
            $("body.category.lookbook").css("padding-top", checkHeight);
        } else {
            $("#left_menu").css("margin-top", "0");
            $("body.category.lookbook").css("padding-top", "0");
        }
    }, 500);
}

$(function () {
    optimiseBandeauMenuAffixHeight();

    $(window).scroll(function () {
        optimiseBandeauMenuAffixHeight();
    });

    $(window).resize(function () {
        optimiseBandeauMenuAffixHeight();
    });
});

function closeMultiShad(id) {
    if (id.indexOf('modBox') != -1) modBoxClose();
    $("#" + id).removeClass('actif');
    $("#" + id).fadeOut();
    if (!$('.lightbox.actif').length) {
        $('#general_shade').fadeOut();
        $('#shade').fadeOut();
    }

    // Remove class opened on Trace for good title FP
    if (id=='item_trace_box') {
        $('#item_trace_box').removeClass('opened');
    }

    if (id == "availabilityInStore" && $('body.product_page').length) {
        $('#shade').removeClass('availabilityInStoreFP');
    }
}

function openMultiShad(id) {
    $("#" + id).addClass('actif').show();
    $("#shade").addClass('actif').show();

    // add to support shade on mobile
    $("#shade")
    .css("display", "block")
    .css("z-index", "10")
    .fadeTo("slow", 0.8)
    .on("click", function () {
        closeMultiShad(id);
    });

    if (id == "availabilityInStore" && $('body.product_page').length) {
        $('#shade').addClass('availabilityInStoreFP');
    }
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var formId = '#return_products_form';
    formId += '_'+(multiTransp?idOrderTransp:idOrder);

    var has_errors = false;

    $(formId+" .return_quantity").each(function () {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0 && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $(formId).serialize();
        data += '&idOrder=' + (multiTransp?idOrderTransp:idOrder) + '&multiTransp=' + multiTransp;

        $.ajax({
            url: path_relative_root + 'ajax_create_etiquette.php',
            type: 'post',
            data: data,
            success: function (res) {
                location.reload();
            }
        });
    }
}
// Commande mobile end

// Guide des tailles

// scroll to the size guide in accordeon
function scrollToSizeGuide() {
    var guideTailleBtn = $('.product_page .product .prod_page_bot .product_form .size_filter .colorSizeTitle #sizeguide');
    var guideTailleAccordeon = $('.product_page .accordion_container #guide_des_tailles');

    guideTailleBtn.on('click', function () {
        $("html, body").animate({ scrollTop: guideTailleAccordeon.offset().top - 70 }, 800);

    });
};

// Eclat sur FP

// insert the price reduction image in the same container as the price
$('body.product_page .product .slider_product_container .eclat_product.rect').insertBefore('body.product_page .prod_page_bot .price_content .price_tag.new_price');

// LA MARQUE MENU STICK
if (document.getElementsByClassName('stick_to_bottom').length > 0) {
    $('.stick_to_bottom').stickyBtn();
}
// insert the loader in btn div
$('body.product_page #content_product .prod_page_bot #item_add_form.product_form #bloc_add_alert.bloc_add_alert #bloc_btn_loader.button').appendTo('body.product_page #content_product .prod_page_bot #item_add_form.product_form #bloc_add_alert.bloc_add_alert #btn_add_alert.button');

// Check if size is selected
function checkFormUpdateCart(evt) {
    var output = function() { return false };
    var value = getInputVal.bind(this.form);
    var colour = value('itm_colour', 'none');
    var size = value('itm_size', 'none');
    var quantity = value('itm_quantity', 'none');
    var head_offset = (window.innerWidth * 0.2921875);
    var form_offset;
    var scroll_delay;
    var produit_id = $('#produit_id').val();

    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined);
    var productBatchId = $(this).data('productid');

    if (productBatchId != produit_id && productBatchId != undefined) {
        this.form.productBatchId = $(this).data('productid');
        this.form.titreObjet = $(this).data('producttitle');
        this.form.prix = $(this).data('productprice');
        this.form.qte = $(this).data('productbatchnumber') * quantity;
    } else {
        this.form.productBatchId = '';
    }

    if ($('.prod_page_bot', '#content_product').length > 0) {
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop;
    }

    if (window.dataLayer !== undefined && (typeof dataLayer_v2 == 'undefined' || dataLayer_v2 != 1)) {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {
                    'product_ref': value('itm_ref')
                }
            }
        });
    }

    if (is_achat_express_v2) {
        quantity = 0;
        var suffix = "_" + this.form.elements['suff'].value;
        var color_id = this.form.elements['couleurProd'].value;
        produit_id = this.form.elements['produit_id'].value;

        datas = { idProduit: produit_id, idCouleur: color_id, idTaille: size, suffix: suffix };
        quantity = checkQty(datas);

        if (colour && size && quantity) {
            output = sendFormUpdateCart.bind(this.form);
        } else {
            //this.checked = false;
        }
    } else {
        if (colour && typeof(size) != 'object' && size && quantity) {
            output = sendFormUpdateCart.bind(this.form);
        } else {
            if (form_offset) {
                scroll_delay = $(window).scrollTop() * 0.8;
                scroll_delay = Math.min(scroll_delay, 400);

                $('html, body').animate({ scrollTop: form_offset - head_offset }, scroll_delay);
            }

            if (!$('.input_check.size:checked').length && $('.noSizeSelected').length) { // Ex : Jonak
                $('.noSizeSelected').addClass('error');
            }

            if (!colour) {
                this.form.itm_colour.classList.add('error');
            }

            if (!size) {
                this.form.itm_size.classList.add('error');
            }

            if (!quantity) {
                this.form.itm_quantity.classList.add('error');
            }

            window.setTimeout(function() {
                var loader = $('.loader');

                if (loader.hasClass('loading')) {
                    loader.removeClass('loading');
                } else {
                    loader.parent().removeClass('loading');
                }
            }, 400);
        }
    }

    return output();
}

//paypal loader
$( function () {
    $(".wrapper_bloc_banque.paypal").on('click touch tap', function() {
        $(this).find(".bank_subtitle").addClass("loader_paypal");
    })

    /*Swiper Initialisation */
    if ($(".swiper-slide", "#sliderBando").length > 1) {

        setTimeout(() => {
            var bandoHeader = new Swiper("#sliderBando", {
                paginationClickable: true,
                preloadImages: true,
                lazyLoading: true,
                loop: true,
                effect: "cube",
                direction: "vertical",
                autoplay: 3000,
                speed: 2200,
                autoplayDisableOnInteraction: false
            });
        }, 500);

    }
});

Eresa.generateStoreBloc = function(stores) {
    var self = this;
    var storeLocator = $('.store_locator');

    // On vide le bloque
    storeLocator.html('');
    // Les magasins avec au moin 1 stock
    var nbStoreAvailable = 0;
    // Les magasins assez proche
    var nbStoreAvailableNear = 0;
    $.each(stores, function(index, store) {
        // On regarde si il y a un horaire de renseigner
        if (store.schedule !== '') {
            // On va mettre la class actif
            store.showSchedule = 'actif';
        } else {
            store.showSchedule = '';
        }
        store.showQuantity = '';
        store.S_article = '';
        store.styleNoStock = 'display:none !important;';

        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            if (store.quantity==null) {
                store.quantity = 0;
            }
        }

            if (store.quantity > 2) {
                // Juste pour mettre un s à articles
                store.S_article = 's';
            }
            store.showQuantity = 'actif';

        if (typeof(eresa_v2) != 'undefined' && eresa_v2 == true) {
            if (store.quantity == 0) {
                store.showQuantity = 'no_stock';
                store.styleButton = 'display:none !important;';
                store.styleNoStock = 'display:block;';
            }
        }

        // Si il n'y a pas assez de stock alors on n'affiche pas le magasin
        if (store.quantity > 0 || typeof(eresa_no_stock) != 'undefined') {
            nbStoreAvailable ++;
            if (store.distance <= self.nbKilometerMax || disable_use_geolocation) {
                var html = Mustache.render(
                    self.storeHtml,
                    store
                );
                $(html).appendTo(storeLocator);
                nbStoreAvailableNear ++;
            }
        }
    });

    var report = $('<div>', {class: 'js_store_report'});
    if (nbStoreAvailable === 0 || nbStoreAvailableNear === 0) {
        if (nbStoreAvailable === 0) {
            report.html(Translator.translate('store_not_available_quantity'));
        } else if (nbStoreAvailableNear === 0) {
            report.html(Translator.translate('store_not_available_near'));
        }
        $(report).appendTo(storeLocator);
    }

    // L'event pour afficher les horaires
    $('.js_have_horaire_store.actif', storeLocator).on('click', function(event) {
        event.preventDefault();
        var sheduleStore = $(this).next('.schedule_store');
        // Pour afficher les horaires du magasins
        if (!sheduleStore.hasClass('actif')) {
            sheduleStore.addClass('actif');
        } else {
            sheduleStore.removeClass('actif');
        }
    });
}

if (document.querySelector("body.faq")) {
    window.addEventListener("load", function() {
        var currentURL = window.location.href;
        // Initialiser la variable pour stocker le chiffre après "faq.php?theme="
        var themeNumber;
        // Vérifier si l'URL contient "faq.php?theme="
        if (currentURL.includes("faq.php?theme=")) {
            // Extraire le chiffre après "faq.php?theme="
            var themeParam = currentURL.split("faq.php?theme=")[1];
            // Convertir la chaîne de caractères en nombre
            themeNumber = parseInt(themeParam, 10);
            showTabFaq(themeNumber, 1);
            $('html, body').animate( { scrollTop: $('.faq_theme_' + themeNumber).offset().top - 60 }, 1000 );
        }
    })
}

if ($('#cookiesLightbox input').length) {
    var inputCookies = $('#cookiesLightbox input');
    var changeTxtToAccept = $('#cookiesLightbox div.w-form-line.w-submit.form_submit #js_cookie_accept span');

    inputCookies.on('change', function () {
        changeTxtToAccept.text(Translator.translate('accept'));
    });
}

if ($('body.faq').length) {
    $('.theme_detail .faq_element').each(function () {
        const faq_title_element = $(this).find('h2').text().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace('securite', 'confidentialite').replace('security', 'privacy').replace('online', 'assistance').replace('client', 'account').replace('stock', 'product');
        const faq_title_element_first = faq_title_element.split(' ')[0];
        $(this).attr("id", faq_title_element_first);
    });

    /* Ancre page FAQ */
    $(document).ready(function () {
        var faq_anchor_name = window.location.hash.substring(1);
        var faq_anchor_name_element = $('#' + faq_anchor_name);

        if (~window.location.href.indexOf("#" + faq_anchor_name)) {
            $('#theme_page').addClass('actif');
            faq_anchor_name_element.addClass('active');
            faq_anchor_name_element.find('.faq_questions').show();
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }
    });
}

// Show not dynamic menu Collection materials
function showitems (el) {
    if ($('.submenu_group_name.not-dynamic').length) {
        var menuWrapper = $(el);
        var itemList = $(el).find('.cat_list');
        var ss_cat_name = $(el).find('.ss_cat_name');

        menuWrapper.toggleClass('triggered');
        itemList.toggleClass('show');
        ss_cat_name.toggleClass('actif');
        $('.ss_cat_name').not(ss_cat_name).removeClass('actif');
        $('.cat_list').not(itemList).removeClass('show');
    }
}

function onLoadProductForm(is_kit, hide_bookinstore, date_arrivage, enable_alert_stock) {
    var produit_id = $('#produit_principal').val();
    var data = {produit_id: new Array(produit_id)};

    data.warehouse_only = isGlobalEActive ? 1 : 0;

    $.ajax({
        url: path_relative_root + create_link('ajax_get_colisages_states_by_color_size'),
        type: 'post',
        data: data,
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            if (res.success && res.result) {
                var has_stock = false;
                var length_item_color = $('.product_form  [name="itm_colour"]').length;

                if (length_item_color) {
                    if ($("input[name=itm_colour]:checked").length) {
                        var sizes_tab = res.result[produit_id][$("input[name=itm_colour]:checked").val()];
                    } else if ($("#prod_couleur").length) {
                        var sizes_tab = res.result[produit_id][$("#prod_couleur").val()];
                    } else {
                        var sizes_tab = [];
                    }
                } else {
                    var sizes_tab = res.result[produit_id][0];
                }

                for (size_id in sizes_tab) {
                    if (sizes_tab.hasOwnProperty(size_id)) {
                        var option_size = $('option', '#prod_taille').filter('[value="' + size_id + '"]');

                        if (sizes_tab[size_id]) {
                            has_stock = true;
                            $('#size_' + size_id).removeClass('disabled');
                            option_size.text(option_size.text().replace(' - ' + Translator.translate('product_no_stock'), ''));
                        } else {
                            $('#size_' + size_id).addClass('disabled');

                            if (option_size.text().indexOf(Translator.translate('product_no_stock')) == -1) {
                                option_size.text(option_size.text() + ' - ' + Translator.translate('product_no_stock'));
                            }
                        }
                    }
                }

                if (has_stock) {
                    $("#add_basket_wrapper").show();
                    $("#link_book_in_store").show();
                    $(".stock_check").show();
                    $("#bloc_add_alert").hide();
                } else {
                    $("#add_basket_wrapper").hide();
                    if (hide_bookinstore == 1) { // if we want to hide CTA "book in store"
                        $("#link_book_in_store").hide();
                    }
                    $(".stock_check").hide();
                    if (date_arrivage == false || date_arrivage.length > 0 && date_arrivage != "0000-00-00 00:00:00") {
                        $("#bloc_add_alert").show();
                    }
                }
            }
        }
    });
}

// Show horaires d'ouverture
let intervalID = null;

function horaireOverture() {
    const $shops = $(".cart.step_2 .wrapper_search_shop .wrapper_relais.records tr");

    if ($shops.length) {
        $shops.each(function () {
            const $horrairOpen = $(this).find("div.kp + div").addClass("horrairOpen");
            const $horraireTable = $(this).find("div.table_horaire");
            $horrairOpen.add($horraireTable).wrapAll('<div class="horrair-wrapper"></div>');
            $(this).find(".horrair-wrapper").insertAfter($(this).find("div.kp_top"));
        });

        $(".cart.step_2 .wrapper_search_shop .wrapper_relais.records .horrairOpen").on("click touch tap", function () {
            const $tableHoraire = $(this).next('.table_horaire');
            $tableHoraire.is(':visible') ? $tableHoraire.hide() : $('.table_horaire').hide() && $tableHoraire.show();
        });

        clearInterval(intervalID);
    }
}

// SWIPER TUNNEL PANIER
function initSwiperTunnel() {
    if ($( ".cartAssociations .swiper-slide" ).length >= 1) {
        tunnelBasketSwiper = new Swiper( ".cartAssociations .cartAssocSwiper", {
            loop: true,
            slidesPerView: 2.2,
            spaceBetween: 4,
            preloadImages: true,
            lazyLoading: true,
            nextButton: '.swiper-cartassoc-next',
            prevButton: '.swiper-cartassoc-prev'
        } );
    }
}

function customizeMapDeliveryContent() {
    intervalID = setInterval( horaireOverture, 500 );

    // Pour mettre les produits selectionnés dans la wishlist
    function basketToWish( draggedElement, fromDrag) {

        var removeBtn;
        if (!fromDrag) {
            removeBtn = draggedElement;
        } else {
            removeBtn = draggedElement.querySelector( ".product_del" );
        }

        $( removeBtn ).closest( '.cart_item_actions_content' ).addClass( 'loading' );

        var productId = $( removeBtn ).attr( "data-productId" );
        var sizeId = $( removeBtn ).attr( "data-sizeId" );
        var colorId = $( removeBtn ).attr( "data-colorId" );
        var isModel = $( removeBtn ).attr( "data-isModel" );
        var idConfigurator = $( removeBtn ).attr( "id-configurator" );
        var needColorId = false;
        var needSizeId = false;

        var data = {
            product_id: productId,
            size_id: sizeId,
            color_id: colorId,
            need_color_id: needColorId,
            need_size_id: needSizeId,
            is_model: isModel,
            id_configurator: idConfigurator
        };

        $.post( path_relative_root + 'ajax_add_to_wishlist.php?time=' + Date.now(), data, function ( resp) {

            if (resp.error) {
                if (resp.message == "login_required") {
                    var html =
                        '<div class="box_wishlist">' +
                        resp.result.error_message +
                        '<div class="wrapper_btn">' +
                        '<a class="button primary" href="#" onclick="wishlistRedirectLogin();">' +
                        resp.result.btn_connexion +
                        "</a>" +
                        "</div>" +
                        "</div>";

                    if ($( '#lightbox_achat_express' ).length > 0) {
                        $( '#lightbox_achat_express' ).fadeOut( "fast", function () {
                            $( '#shad' ).css( 'display', 'none' );
                        } );
                    }
                    alert( html );
                    $( "#abox .form_submit" ).css( "display", "none" );
                } else {
                    alert( Translator.translate( resp.result.error_message ) );
                    return false;
                }
            } else {
                if (window.dataLayer !== undefined) {
                    window.dataLayer.push( {
                        'event': 'addToWishlist',
                    } );
                }

                var dl_v3_obj = resp.result.dlv3_wishlist_item;
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys( dl_v3_obj ).length > 0) {
                    DataLayerTrigger.addToWishList( dl_v3_obj[ 'items' ], dl_v3_obj[ 'actionField' ] )
                }

                removeFromBasket( removeBtn, true );
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && productId !== null && productId !== undefined && dl_v3_wishlist_impressions !== undefined) {
                    DataLayerTrigger.cart_product_action( dl_v3_impressions, dlv3_action_fields, productId, "remove_from_cart" );
                }
            }

            setTimeout( initSwiperTunnel, 1000 );

            hideModalAction();
        } );
    }
}

// moves alma widget to below order recap
$( function () {
    if ($('.order_recap #alma-widget').length) {
        $('#alma-widget').appendTo($('.elem_tunnel.right_side'));
    }
});

// Toggles side menu of given id comming from given side
function toggleMenu(id) {
    if ($('legend.open').length) {
        var form = document.getElementById('#filters_form');

        $('legend.open', form).each(function() {
            toggleFiltersMenu.call(this);
        });
    }

    var menu = document.getElementById(id + '_menu');
    var shad = document.getElementById('shade');
    var iconMenu = document.getElementById('main_menu_btn');

    if (menu) {
        if (menu.classList.toggle('open') && shad) {
            shad.classList.add('visible');
            document.body.classList.add("noscroll");
            shad.classList.add('forMenu');
            if (id == "left") {
                iconMenu.classList.add("menu_open");
                shad.classList.add('left_menu_open');
            } else {
                shad.classList.add('forFilters');
            }
        } else if (shad) {
            shad.classList.remove('visible');
            shad.classList.remove('forMenu');
            shad.classList.remove('forFilters');
            document.body.classList.remove("noscroll");
            if (id == "left") {
                iconMenu.classList.remove("menu_open");
                shad.classList.remove('left_menu_open');
            }
        }
    } else if (shad) {
        shad.classList.toggle('visible');
    }
}

// Toggles user account menu visibility
function toggleAccountMenu() {
    var $btn = $(this),
        menu = $(document.getElementById('account_menu')),
        marker = $('.marker', menu),
        body = $('body'),
        shad = document.getElementById('shade');
    offset = this.offsetLeft + (0.5 * this.offsetWidth);
    offset += -6;
    marker.css('left', offset);
    if (menu.hasClass('active')) {
        body.removeClass('noscroll');
        $btn.add(menu).removeClass('active');
        shad.classList.remove('visible');
        shad.classList.remove('account-menu');
    } else {
        body.addClass('noscroll');
        $btn.add(menu).addClass('active');
        shad.classList.add('visible');
        shad.classList.add('account-menu');
    }
}

// Close menu mobile by shade
$(document).on('click','#shade.forMenu', function() {
    $("body").removeClass("noscroll");
    $("#main_menu_btn").removeClass("menu_open");
    $("#shade").removeClass('visible forMenu forFilters');
    $("#left_menu").removeClass('open');
    $("#shade").removeClass('left_menu_open');
});

// Triggers on attribute alteration
function onAttrSelect(idProduit) {
    var form = document.getElementById('item_add_form'),
            colors = form.itm_colour,
            sizes = form.itm_size,
            quantities = form.itm_quantity,
            color, size;

    if (this.value) {

        this.classList.remove('error');
    }

    color = (colors && colors.selectedIndex !== -1) ? colors.value : 'none';

    if (color !== 'none') {

        updateColor.call(colors.options[colors.selectedIndex]);

        if (this !== window && this.classList.contains('color')) {

            setAvailableSizes.call(this);
        }
    }

    size = (sizes && sizes.selectedIndex !== -1) ? sizes.value : 'none';

    if (quantities) {
        $.ajax({
            url: path_relative_root + create_link('ajax_liste_quantity'),
            type: 'get',
            data: {idprod: idProduit, idcouleur: color, idtaille: size},
            success: function (res) {
                if (quantities.parentElement !== null) {
                    quantities.parentElement.innerHTML = res.trim();
                }

                result = $($('#qteProd option:selected'),res);

                if ($("#bloc_add_alert").length>0) {
                    if (result.hasClass('no_stock')) {
                        $("#bloc_add_alert").show().css('display', 'flex');
                        $("#add_basket_wrapper").hide();
                        $("#link_book_in_store").hide();
                        $(".stock_check").hide();
                    } else {
                        $("#bloc_add_alert").hide();
                        $("#add_basket_wrapper").show();
                        $("#link_book_in_store").show();
                        $(".stock_check").show();
                    }
                } else {
                    if (result.hasClass('no_stock')) {
                        $("#add_basket_wrapper").hide();
                        $("#link_book_in_store").hide();
                        $(".stock_check").hide();
                        $("#size_is_not_available").show();
                    } else {
                        $("#add_basket_wrapper").show();
                        $("#link_book_in_store").show();
                        $(".stock_check").show();
                        $("#size_is_not_available").hide();
                    }
                }
            }
        });
    }

    var idStore = getCookie('wshopStoreId');
    if (idStore != undefined && idStore > 0) {
        getProductInfoByStore(idProduit, color, size, idStore)
    }
    checkProductInWishlist();
}

/**
 * Check if 'element' is in viewport
 */
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/**
 * Global .item_container slider initialization
 * isToggled => is true when from a toggle between 1 or 2 products per line (function defined in _app)
 */
function initItemSlider(isToggled) {
    if ($(".prodItemSlider, .searchItemSlider").length) {
        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) {
            // Toggle view is triggerred

            setTimeout(function () {
                $(".prodItemSlider, .searchItemSlider").each(function () {
                    if ($(this).hasClass("initialized")) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else {
            // From page load and scroll

            $(".prodItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    // Prevent initialized sliders to go back to the first frame during scroll
                    let swiper = new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    // When duplicating video, we only need to remake an observer
                    swiper.on('init', function (swiper) {
                        initDailymotionVideos();
                    });

                    swiper.init();

                    $(this).addClass("initialized");
                }
            });

            $(".searchItemSlider").each(function () {
                if (isInViewport($(this)) && !$(this).hasClass("initialized")) {
                    new Swiper($(this), {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: ".itemSliderPagination",
                    });

                    $(this).addClass("initialized");
                }
            });
        }
    }
}

/**
 * Load global .item_container slider initialization on window scroll
 */
window.addEventListener("scroll", function () {
    initItemSlider(false);
});

/**
* Load global .item_container slider initialization for those present on viewport on page load
*/
window.addEventListener("load", function () {
    initItemSlider(false);
});

function openColorAchatExpressV2() {

    var $div_color = $(this.form).find('.productColorFieldset');
    var $div_size = $(this.form).find('.productSizeFieldset');
    var div_color_actif = $div_color.hasClass('actif');

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    $('.achat_express').hide();
    $('.productColorFieldset.actif').removeClass('actif');
    $('.productSizeFieldset.actif').removeClass('actif');

    if (lightBoxAlertStock) {
        lightbox.addClass('displayColors');
    }
    if (div_color_actif) {
        $(this.form).find('.achat_express').hide();
        $div_color.removeClass('actif');

    } else {
        $(this.form).find('.achat_express').show();
        $div_color.addClass('actif');
        $div_size.removeClass('actif');
    }

    // open achat expressV2 shade
    $('#shade').addClass('isExpress visible');
    // hide floating add basket CTA
    $('#floating_add_basket').addClass('hide');

    // close expressV2 color selection lightbox and shade
    $("#shade").on("click touch", function () {
        $("#shade").removeClass("isExpress").removeClass("visible");
        $div_color.removeClass("actif");
        lightbox.hide();

        // hide floating add basket CTA
        $('#floating_add_basket').removeClass('hide');

        $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper').removeClass('stop_transform_gondola');
        $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper .swiper-slide').removeClass('stop_transform_gondola');

        $('#entretien_gondole .tete_de_gondole.swiper-wrapper').removeClass('stopTransform');
        $('#entretien_gondole .tete_de_gondole .swiper-slide').removeClass('stopTransform');

        if (($('body.homepage').length || $('body.cms_page_new').length) && $('.home-module.push-product-module').length) {
            $('.home-module.push-product-module .pushProductHomeWrapper .pushProductHome .swiper-wrapper').removeClass('stop_transform');
        }
    });

    $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper').addClass('stop_transform_gondola');
    $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper .swiper-slide').addClass('stop_transform_gondola');

    $('#entretien_gondole .tete_de_gondole.swiper-wrapper').addClass('stopTransform');
    $('#entretien_gondole .tete_de_gondole .swiper-slide').addClass('stopTransform');

    if (($('body.homepage').length || $('body.cms_page_new').length) && $('.home-module.push-product-module').length) {
        $('.home-module.push-product-module .pushProductHomeWrapper .pushProductHome .swiper-wrapper').addClass('stop_transform');
    }
}

function hideAchatExpress() {

    $("#shade").removeClass("visible").removeClass("isExpress");
    $(this.form).find('.achat_express').hide();

    $(this.form).find('.actif').removeClass('actif');

    $(this.form).find('.actif').removeClass('actif');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }

    // show floating add basket
    $('#floating_add_basket').removeClass('hide');

    $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper').removeClass('stop_transform_gondola');
    $('#store_gondola #wrapper_products_store #wrapper_visus_store .swiper-wrapper .swiper-slide').removeClass('stop_transform_gondola');

    $('#entretien_gondole .tete_de_gondole.swiper-wrapper').removeClass('stopTransform');
    $('#entretien_gondole .tete_de_gondole .swiper-slide').removeClass('stopTransform');

    if (($('body.homepage').length || $('body.cms_page_new').length) && $('.home-module.push-product-module').length) {
        $('.home-module.push-product-module .pushProductHomeWrapper .pushProductHome .swiper-wrapper').removeClass('stop_transform');
    }
}

/* HEADER NAV */
if ($(".menu_part.bottom").length) {

    $(".menu_part.bottom").insertBefore("nav.general_menu");
    $(".menu_part.bottom .switchLangWrapper").insertAfter("nav.general_menu");

    // avoid redirection on collection menu
    if ($(".menu_part.bottom span#menu_collection a").length) {
        $(".menu_part.bottom span#menu_collection a").attr("href", "javascript:void(0)");
    }
}

function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event == "undefined") {
        button_add_to_wishlist_initial_click_event = $(
            "#addToWishlistButton"
        ).attr("onclick");
    }
    if (typeof button_add_to_wishlist_initial_label == "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let checkColor = $("#prod_couleur option:selected");
    let checkSize = $("#prod_taille");
    let colorSelected = checkColor.length ? checkColor.val() : "";
    let sizeSelected = checkSize.length ? checkSize.val() : "";

    if (
        typeof productId != "undefined" &&
        colorSelected !== "" &&
        sizeSelected !== ""
   ) {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                    return true;
                } else {
                    changeBtnAddWishlist(false);
                    return false;
                }
            },
        });
    } else {
        changeBtnAddWishlist(false);
        return false;
    }
}

function changeBtnAddWishlist(in_wishlist) {
    $("#addToWishlistButton").attr(
        "onclick",
        button_add_to_wishlist_initial_click_event
    );

    if (in_wishlist) {
        $("span", "#addToWishlistButton").html(
            Translator.translate("exist_to_wishlist_button_label")
        );
        $("#addToWishlistButton")
            .removeClass("addToWishlistButton")
            .addClass("existToWishlistButton");
        $("#addToWishlistButton").removeClass("heart").addClass("heart_b");
    } else {
        $("span", "#addToWishlistButton").html(
            Translator.translate("wishlist")
        );
        $("#addToWishlistButton")
            .removeClass("existToWishlistButton")
            .addClass("addToWishlistButton");
        $("#addToWishlistButton").removeClass("heart_b").addClass("heart");
    }
}

function changeVisu(field, path, evt) {}

function addSelectedProductToWishlist(button) {

    if (selectedProducts.length != 0) {
        if (checkGiftPoints()) {
            $.post(path_relative_root + create_link('ajax_add_panier_wishlist'), {data: selectedProducts.join(",")}, function (resp) {

                var data_layer_products = window.data_layer_products;
                var dtl_products = [];

                if (resp == "ok") {
                    if (typeof dataLayer_v2 != 'undefined' && dataLayer_v2 === '1') {
                        if (Object.keys(data_layer_products).length > 0) {
                            for (const property in data_layer_products) {
                                if (selectedProducts.indexOf(property) !== -1) {
                                    dtl_products.push(data_layer_products[property])
                                }
                            }
                        }
                        if (dtl_products.length > 0) {
                            var dataLayer = window.dataLayer || []
                            dataLayer.push({
                                "event": "addToCart",
                                "ecommerce": {
                                    'currencyCode': siteCurrency.symbol_right,
                                    "add": {
                                        "products": dtl_products
                                    }
                                }
                            })

                        }
                    }

                    document.location.href = path_relative_root + create_link('order_basket');
                } else {
                    Alert(Translator.translate("err_no_points"));
                }
            }
        );
        } else {
            Alert(Translator.translate("err_no_points"));
        }
    }
}

// Page entretien -> on click menu, auto-scroll to section
$(document).ready(function() {
    $('#entretienMenuSwiper .menu-item').on('click tap touch',function(event) {
        event.preventDefault();
        $('.menu-item').removeClass('active'); // Remove active class from all menu items
        $(this).addClass('active');

        var targetId = $(this).attr('href').substring(1);
        var targetSection = $('#' + targetId);
        var offset = targetSection.offset().top - 80;
        $('html, body').animate({
        scrollTop: offset
        }, 1800 );

        var activeSlideIndex = $('.menu-item.active').parent('.swiper-slide').index();
        // Slide to the center of the slide containing the active menu item
        satelliteTgSwiper.slideTo(activeSlideIndex);
    });

    // Page entretien menu swiper
    if ($('body.fr.entretien #entretienMenuSwiper').length) {
        $('#entretienMenuSwiper .menu').addClass('swiper-slide');
        var satelliteTgSwiper = new Swiper('#entretienMenuSwiper', {
            slidesPerView: 3.3,
            initialSlider: 1,
        });
    }

    initDailymotionVideos();
} );

// init slider after filter done on page rayon
function changeajaxfilterRayonExtra() {
    initItemSlider(false);
}

// init slider after filter done on page recherche
function generateNewBlocSearchExtra() {
    initItemSlider(false);
}

function checkValidityRequestOfReturn() {
    var selectId = $(this).attr('id');
    var selectedValue = $(this).val();

    if (selectId.includes("return_quantity")) {
        valueIdTailleQuantity = selectId.split("return_quantity_")[1];

        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            if (!$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", false);
                $('#' + selectId).addClass('choice_unlocked_both');
            }
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && !$('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            } else if ($('#' + selectId).hasClass('choice_locked') && $('#return_choice_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked');
                $('#return_choice_' + valueIdTailleQuantity).addClass('choice_unlocked_both').prop("disabled", true);
                $('#return_choice_' + valueIdTailleQuantity).val(0);
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_locked_both')
                $('#' + selectId).removeClass('choice_locked_both');
                if (document.querySelector('.choice_locked_both')) {
                    $('#return_choice_' + valueIdTailleQuantity).addClass('choice_locked_both');
                    $('#' + selectId).addClass('choice_locked_both');
                    $('#return_choice_' + valueIdTailleQuantity).removeClass('choice_unlocked_both');
                    $('#' + selectId).removeClass('choice_unlocked_both');
                }
            }
        }
    } else if (selectId.includes("return_choice")) {
        valueIdTailleChoice = selectId.split("return_choice_")[1];
        if (selectedValue != 0) {
            $('#' + selectId).addClass('choice_locked');
            $('#return_quantity_' + valueIdTailleChoice).addClass('choice_locked_both');
            $('#' + selectId).addClass('choice_locked_both');
            $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_unlocked_both');
            $('#' + selectId).removeClass('choice_unlocked_both');
        } else {
            if ($('#' + selectId).hasClass('choice_locked') && $('#return_quantity_' + valueIdTailleQuantity).hasClass('choice_locked')) {
                $('#' + selectId).removeClass('choice_locked');
                $('#return_quantity_' + valueIdTailleChoice).addClass('choice_unlocked_both');
                $('#' + selectId).addClass('choice_unlocked_both');
                $('#return_quantity_' + valueIdTailleChoice).removeClass('choice_locked_both');
                $('#' + selectId).removeClass('choice_locked_both');
            }
        }
    }

    if (!$('.choice_unlocked_both').length && $('.choice_locked').length) {
        $(".divBtnPrintPdf").show();
    } else {
        $(".divBtnPrintPdf").hide();
    }
}

$(document).ready(function () {
    $(".return_products_form select").change(function () {
        var selectedElement = $(this);
        checkValidityRequestOfReturn.call(selectedElement);
    });
});

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form),
        id = value('type_nav', 'tous');
    var type = value('type_tri'),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById('wrapper_couleur_filtre').innerHTML = filterformColors;
            document.getElementById('wrapper_taille_filtre').innerHTML = filterformSize;
        }

        form.reset();
        
        // Force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP
        $('#filters_menu input[checked]').not('#wrapper_sscat_filtre input[checked]').removeAttr("checked"); 
    }

    /*$('legend.open', form).each(function() {
        toggleFiltersMenu.call(this);
    });*/

    // Check obj type
    if (form.sscat) {
        // Attention ce n'est pas des sscat mais des bien objets (views_mobile_new/wrapper_filters.php)
        idObjTmp = value('sscat');

        if (idObjTmp && idObjTmp.length > 0) {
            type = 'obj';
            $('#object_id').val(idObjTmp);
        } else {
            type = 'subcat';
        }
    }

    $('#type_tri').val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('button')) {
        btn.nextElementSibling.style.display = 'block';
    }

    // Get new elements
    if (from_type == 'product') {
        paginationGoToPage.call(this.form, 1);
    } else {
        generateNewBlocSearch.call(form, value('nb_prod'), 0, btn, 0, 0, type, idObj);
    }

    toggleMenu('filters', 'right');

    // Prevent default action
    return false;
}
